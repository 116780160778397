import React from 'react';
import { connect } from 'react-redux';
import { Button, TabList, Input, DateTimeRangePicker } from 'tyb'

// const { loadApplyUnauditeds, loadApplyReturns ,loadApplyPasses, } = iceStarkData.store.get('commonAction')?.batchApply;
import WaitConfirm from './components/WaitConfirm';
import AlreadyPassed from './components/AlreadyPassed';
import NotThrough from './components/NotThrough';
import './index.scss';

@connect(
  state => ({
    applyUnauditeds: state.batchApply.applyUnauditeds,
    applyPasses: state.batchApply.applyPasses,
    applyReturns: state.batchApply.applyReturns,
    permissionIds: state.users.permissionIds||[],
  }),
  {
    
  }
)
class CodePackageApplication extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
      tabList: [
        {
          value: 'waitconfirm',
          text: `待审核(${props.applyUnauditeds && props.applyUnauditeds.content ? props.applyUnauditeds.content.length : '0'})`
        },
        {
          value: 'alreadypassed',
          text: `已通过(${props.applyPasses && props.applyPasses.content ? props.applyPasses.content.length : '0'})`
        },
        {
          value: 'notthrough',
          text: `未通过(${props.applyReturns && props.applyReturns.content ? props.applyReturns.content.length : '0'})`
        }
      ],
      nowTab: 'waitconfirm',
    }
  }

  changeTab = (value) => {
    this.setState({
      nowTab: value
    })
  }

  
  applyCode = () => {
    this.props.history.push('/codeapplyandauditor/codeApplication');
  }


  render() {
    const { permissionIds } = this.props;
    const { tabList, nowTab } = this.state;
    return (
      <div className="CodePackageApplication">
        <div className="search-bar">
          {
            permissionIds.includes('code.batchApply.apply.add') && 
            <Button onClick={this.applyCode}>申请码</Button>
          }
          <div className="main-block">
            <TabList
              list={[
                {
                  value: 'waitconfirm',
                  text: `待审核(${this.props.applyUnauditeds && this.props.applyUnauditeds.totalElements ? this.props.applyUnauditeds.totalElements : '0'})`
                },
                {
                  value: 'alreadypassed',
                  text: `已通过(${this.props.applyPasses && this.props.applyPasses.totalElements ? this.props.applyPasses.totalElements : '0'})`
                },
                {
                  value: 'notthrough',
                  text: `未通过(${this.props.applyReturns && this.props.applyReturns.totalElements ? this.props.applyReturns.totalElements : '0'})`
                }
              ]}
              value={nowTab}
              onChange={this.changeTab}
            >
            </TabList>
            <WaitConfirm isShow={nowTab === 'waitconfirm'} noDateButton={this.applyCode} />
            <AlreadyPassed isShow={nowTab === 'alreadypassed'} noDateButton={this.applyCode}/>
            <NotThrough isShow={nowTab === 'notthrough'} noDateButton={this.applyCode}/>
          </div>
        </div>
      </div>
    )
  }
}

export default CodePackageApplication;
