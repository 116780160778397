import React from 'react';
import { connect } from 'react-redux';
import { DataTable, Pager, Dialog, Tip } from 'tyb'

const { loadSelections } = iceStarkData.store.get('commonAction')?.factory;
const { loadApplyPasses, viewAudit, sendBatchs } = iceStarkData.store.get('commonAction')?.batchApply;
const { updateSendAgainDialogStatus } = iceStarkData.store.get('commonAction')?.ui;
import SearchBar from '../../components/SearchBar';
import ViewDialog from '../../components/ViewDialog';
import { formatDate } from 'ucode-utils';
import { handSortOrder } from 'ucode-utils';
import '../index.scss';
import enums from '@/assets/enum.json';
@connect(
  state => ({
    viewAudit: state.batchApply.viewAudit,
    factorySelections: state.factory.factorySelections,
    applyPasses: state.batchApply.applyPasses,
    loadApplyPassesLoading: state.batchApply.loadApplyPassesLoading,
    sendAgainDialogStatus: state.ui.sendAgainDialogStatus,
    permissionIds: state.users.permissionIds||[],
  }),
  {
    loadSelections: loadSelections.REQUEST,
    loadApplyPasses: loadApplyPasses.REQUEST,
    updateSendAgainDialogStatus,
    loadViewAudit: viewAudit.REQUEST,
    sendBatchs: sendBatchs.REQUEST,
  }
)
class AlreadyPassed extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sortCreatedTimeOrder: null,
      factoryNameFilter: [],
      tableColumns: this.getTabColumns(),
      perPageCountList: [20, 50, 100],
      params: {
        idOrName: '',
        applyStart: '',
        applyEnd: '',
        factoryIds: '',
        page: 0,
        size: 20,
        sort: ''
      },
      viewDialogStatus: false,
      viewObject: {},
      sendAgainDialogStatus: false,
      daySendCount: 0,
    }
  }

  componentDidMount() {
    const { loadSelections, loadApplyPasses } = this.props;
    loadSelections({factoryType: 'PRINT'});
    loadApplyPasses(this.state.params);

  }

  changeSearchBox = (e) => {    
    const params = this.state.params;
    params.idOrName = e.target.value;
    this.setState({
      params
    })
  }

  changeDataRange = (value) => {
    const params = this.state.params;
    params.applyStart = isNaN(new Date(value.fromFormat).getTime()) ? '' :  new Date(value.fromFormat).getTime();
    params.applyEnd = isNaN(new Date(value.toFormat).getTime()) ? '' :  new Date(value.toFormat).getTime();
    this.setState({
      params
    })
  }

  search = () => {
    const params = this.state.params;
    params.page = 0;
    this.setState({
      params
    });
    this.props.loadApplyPasses(params);
  }

  clear = () => {
    const params = this.state.params;
    params.idOrName = '';
    params.applyStart = '';
    params.applyEnd = '';
    this.setState({
      params
    })
  }

  getTabColumns = () => {
    const { permissionIds } = this.props;
    return [
      {
        key: 'id',
        dataIndex: 'id',
        title: '码包名称/ID',
        render: (value, record) => <div>
          <div title={record.name}><span className="text-overflow">{record.name}</span></div>
          <div title={value}><span className="text-overflow">{value}</span></div>
        </div>
      },
      {
        key: 'totalTagCount',
        dataIndex: 'totalTagCount',
        title: '申请码量',
        render: (value, record) => {
          if (record.combinationTagType == 'SINGLE_TAG') {
            return <div>
              <div title={value}><span className="text-overflow">{value}</span></div>
            </div>
          }
          else {
            return <div>
            <div title={`${value}(${record.unitCount}套)`}><span className="text-overflow">{value}({record.unitCount}套)</span></div>
          </div>
          }
        }
      },
      {
        key: 'batchs',
        dataIndex: 'batchs',
        title: '文件批次个数',
      },
      {
        key: 'eseConfigName',
        dataIndex: 'eseConfigName',
        title: '码印刷规格',
      },
      {
        key: 'combinationTagType',
        dataIndex: 'combinationTagType',
        title: '规格类型',
        render: (value) => <div title={enums.combinationTagType[value] ? enums.combinationTagType[value].name : ''}>
        <span>{enums.combinationTagType[value] ? enums.combinationTagType[value].name : ''}</span>
    </div>
      },
      {
        key: 'seqStartToEnd',
        dataIndex: 'seqStartToEnd',
        title: '码起止序号',
      },
      {
        key: 'factoryName',
        dataIndex: 'factoryName',
        title: '包材印刷厂',
      },
      {
        key: 'createdTime',
        dataIndex: 'createdTime',
        title: '申请时间',
        render: (value) => <div title={formatDate(value)}>
          <span className="text-overflow">{formatDate(value)}</span>
        </div>
      },
      {
        key: 'downLoadCount',
        dataIndex: 'downLoadCount',
        title: '文件下载次数',
      },
      {
        key: 'sendSuccess',
        dataIndex: 'sendSuccess',
        title: '发送状态',
        render: (value) => <div title={value?'已发送':'未发送'} style={{ color: value ? '' : '#E1504A' }}>
        <span className="text-overflow">{value?'已发送':'未发送'}</span>
      </div>
      },
      {
        key: 'op',
        dataIndex: 'op',
        title: '操作',
        render: (value, record) => 
        <div className="table-operate-btn">
          <span onClick={() => {
            this.setState({
              viewDialogStatus: true,
              viewObject: record
            })
          }}>查看</span>
          {
            permissionIds.includes('code.batchApply.apply.sendBatchs') &&
            <span onClick={() => {this.sendAgain(record.id)}}>再次发送</span>
          }
        </div>
      },
    ]
  }

  handleSortCreatedTime = () => {
    const newOrder = handSortOrder(this.state.sortCreatedTimeOrder);
    const params = this.state.params;
    const sort = `createdTime,${newOrder}`;
    params.sort = sort;
    this.setState({ params, sortCreatedTimeOrder: newOrder });
    this.props.loadApplyPasses(params);
  }

  renderCreatedTimeSortHeader = (col) => {
    return (
      <DataTable.SortableColumnHeader
        order={this.state.sortCreatedTimeOrder}
        onSort={this.handleSortCreatedTime}
      >
        {col.title}
      </DataTable.SortableColumnHeader>
    )
  }

  handleFactoryNameFilter = (value) => {
    
    const params = this.state.params;
    params.factoryIds = value;
    params.page = 0;
    this.setState({
      params,
      factoryNameFilter: value
    });
    this.props.loadApplyPasses(params);
  }

  renderFactoryNameFilterHeader = (col) => {
    const { factorySelections = [] } = this.props;
    const list = factorySelections.map(v => {
      return {
        text: v.name,
        value: v.id
      }
    })

    return (
      <DataTable.FilterableColumnHeader
        list={list}
        value={this.state.factoryNameFilter}
        onFilter={this.handleFactoryNameFilter}
      >
        {col.title}
      </DataTable.FilterableColumnHeader>
    )
  }



  getColumns = () => {
    return this.getTabColumns().map(column => {
      if (column.key === 'createdTime') {
        return {
          ...column,
          headerRender: this.renderCreatedTimeSortHeader
        }
      } else if (column.key === 'factoryName') {
        return {
          ...column,
          headerRender: this.renderFactoryNameFilterHeader
        }
      } else {
        return column;
      }
    });
  }

  onPageChange = (val) => {
    const { params } = this.state;
    params.page = val;
    this.setState({ params });
    this.props.loadApplyPasses(params);
  }

  onPerPageChange = (val) => {
    const params = this.state.params;
    params.size = val;
    params.page = 0;
    this.setState({
      params
    });
    this.props.loadApplyPasses(params);
  }

  handleCancelViewDialog = () => {
    this.setState({
      viewDialogStatus: false,
      viewObject: {}
    })
  }

  sendAgain = (id) => {
    this.setState({
      sendBatchsId: id
    })
    this.props.loadViewAudit(id)
    this.props.updateSendAgainDialogStatus(true);
  }

  handleOkSendAgainDialog = () => {
    this.props.sendBatchs(this.state.sendBatchsId)
  }

  handleCancelSendAgainDialog = () => {
    this.props.updateSendAgainDialogStatus(false);
  }

  render() {
    const { params, perPageCountList, viewObject, viewDialogStatus, daySendCount } = this.state;
    const { isShow, noDateButton, applyPasses = {}, loadApplyPassesLoading, sendAgainDialogStatus, viewAudit = {}, permissionIds } = this.props;
    const applyPassesLength = applyPasses.content ? applyPasses.content.length : 0 
    return (
     <div style={{ display: isShow ? 'unset' : 'none' }} className={`${applyPassesLength > 0 && !loadApplyPassesLoading? 'table-tr-height50' : 'table-tr-middle'}`}>
        <SearchBar
            searchText={params.idOrName}
            search={this.search}
            clear={this.clear}
            dataRange={{ from: params.applyStart, to: params.applyEnd }}
            changeDataRange={this.changeDataRange}
            changeSearchBox={this.changeSearchBox}
        />
        <DataTable 
          data={applyPasses.content}
          columns={this.getColumns()}
          rowKey="id"
          loading={loadApplyPassesLoading}
          messageRender={({ data, error }) => {
            if (error != null) { return error.msg; }
            if (data == null || data.length === 0) { 
              if (permissionIds.includes('code.batchApply.apply.add')) {
                return <span className="text-weak">暂无数据，您可以点击左上角<span onClick={noDateButton}>申请码</span></span>; 
              } else {
                return <span className="text-weak">暂无数据</span>; 
              }
              
            }
          }}
        />
        { applyPassesLength > 0 && !loadApplyPassesLoading ? <div className="page-padding20">
          <Pager 
            total={applyPasses.totalElements}
            current={params.page}
            perPageCountList={perPageCountList}
            perPageCount={params.size}
            onPageChange={this.onPageChange}
            onPerPageChange={this.onPerPageChange}
          />
        </div> : '' }
        { viewDialogStatus &&
          <ViewDialog viewType="alreadypassed" viewObject={viewObject} handleCancel={this.handleCancelViewDialog}  />
        }
        <Dialog
          title="再次发送码包"
          visible={sendAgainDialogStatus}
          okText="确认"
          cancelText="取消"
          onOk={this.handleOkSendAgainDialog}
          onCancel={this.handleCancelSendAgainDialog}
        >
          <div>
            <Tip hasClose={false} type="warning">每日每个码包可发送3次，您还可以发送{3 - (viewAudit.daySendCount !== undefined ? viewAudit.daySendCount : 0)}次</Tip>
            { viewAudit.daySendCount !== 3 &&
            <div>
              确认再次发送码包下载链接至码包接收者{viewAudit.packetReceiver}和码包解压密码接收者{viewAudit.passwordReceiver}吗?
            </div>
            }
          </div>

        </Dialog>
     </div>
    ) 
  }
}

export default AlreadyPassed;